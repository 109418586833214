import Cookies from "js-cookie"
import React, { useState } from "react"
import { Redirect, useHistory } from "react-router"
import { Link } from "react-router-dom"
import styles from "../../scss/components/Esports/_Form.module.scss"
import CustomCheckBox from "./CustomCheckBox"
import Success from "./Success"
import {localidades} from "./localidades.json"

export default function Form({setStep}) {
  let history = useHistory()
  const [data, setData] = useState({
    name: "",
    dni: "",
    email: "",
    city: "",
  })
  // FIRST QUESTIONS
  const [selectedInput, setSelectedInput] = useState("")
  // SECOND QUESTIONS
  const [selectedInput2, setSelectedInput2] = useState("")
  // TERMS BUTTON
  const [isChecked, setIsChecked] = useState(false)
  const [validateError, setValidateError] = useState("")
  const [cities, setCities] = useState(localidades)

  const handleChange = inputValue => {
    setSelectedInput(inputValue)
    setStep("6")
  }
  const handleChange2 = inputValue => {
    setSelectedInput2(inputValue)
    setStep("7")
  }

  const handleInputChange = e => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
      [e.target.dni]: e.target.value,
      [e.target.email]: e.target.value,
      [e.target.twitter]: e.target.value,
      [e.target.city]: e.target.value,
      // [e.target.firstQ]: e.target.value,
      // [e.target.secondQ]: e.target.value,
    })
  }
  function validateEmail(data) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    // return re.test(String(data.email).toLowerCase())
    const email = data.email
    return re.test(String(email).toLowerCase())
  }
  function validateForm() {
    if (validateEmail(data)) {
      if (
        !data.name ||
        !data.dni ||
        !data.twitter ||
        !data.city ||
        !selectedInput ||
        !selectedInput2
      ) {
        // ACA SI HAY ALGUN CAMPO VACIO
        setValidateError("empty")
      } else if (isChecked) {
        // ACA SI TODO ESTA BIEN
        setValidateError("success")

        let gamingAsoc = ""

        if (selectedInput === "firstq-poco") {
          gamingAsoc = "Lo asocio un poco"
        } else if (selectedInput === "firstq-no") {
          gamingAsoc = "No lo asocio para nada"
        } else if (selectedInput === "firstq-maso") {
          gamingAsoc = "Mas o menos"
        } else if (selectedInput === "firstq-bastante") {
          gamingAsoc = "Lo asocio bastante"
        } else if (selectedInput === "firstq-completamente") {
          gamingAsoc = "Lo asocio completamente"
        }

        let user = {
          name: data.name,
          dni: data.dni,
          email: data.email,
          twitter: data.twitter,
          state: data.city,
          gamingAsoc: gamingAsoc,
          drinksBeer: selectedInput2 === "secondq-si",
          td: Cookies.get("_td"),
        }

        fetch("https://wabudweiser-prd.azurewebsites.net/esports", {
          // fetch("http://localhost:8080/esports", {
          method: "POST",
          body: JSON.stringify(user),
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        }).then(response => {
          if (response.ok) {
            history.push("/esports/success")
          } else {
            setValidateError("duplicated")
            response.text().then(text => {
              console.error("Error guardando los datos. \n", text)
            })
          }
        })
      } else {
        // ACA SI FALTA ACEPTAR LOS TERMINOS Y CONDICIONES
        setValidateError("terms")
      }
    } else {
      // ACA SI EL EMAIL ES INVALIDO
      setValidateError("email")
    }
  }

  const MessageValidate = () => {
    switch (validateError) {
      case "email":
        return <h1 className={styles.error}>EL EMAIL ES INVALIDO</h1>
      case "empty":
        return <h1 className={styles.error}>DEBE COMPLETAR TODOS LOS CAMPOS</h1>
      case "duplicated":
        return (
          <h1 className={styles.error}>
            El email o el dni ingresado ya está en uso
          </h1>
        )
      case "terms":
        return (
          <h1 className={styles.error}>
            DEBE ACEPTAR LOS TERMINOS Y CONDICIONES
          </h1>
        )
      case "success":
        return null
      default:
        return null
    }
  }

  const handleSubmit = e => {
    e.preventDefault()
    validateForm()
  }
  const CheckboxInput = ({ name, label, isChecked, setIsChecked ,setStep}) => {
    const toggleCheck = e => {
      setIsChecked(() => !isChecked)
      setStep("8")
    }
    return (
      <div>
        <input
          type="checkbox"
          className={styles.termsRadio}
          name={name}
          id={name}
          checked={isChecked}
          onChange={toggleCheck}
        />
        <label htmlFor={name}>{/* <span>{label}</span> */}</label>
      </div>
    )
  }
  return (
    <div className={styles.container}>
      <form onSubmit={handleSubmit} className={styles.formContainer}>
        <input
          type="text"
          name="name"
          onChange={handleInputChange}
          className={styles.simpleInput}
          placeholder="Nombre y Apellido"
          onFocus={()=>{setStep("1")}}
        />
        <input
          type="number"
          name="dni"
          onChange={handleInputChange}
          className={styles.simpleInput}
          placeholder="DNI"
          onFocus={()=>{setStep("2")}}
        />
        <input
          type="email"
          className={styles.simpleInput}
          placeholder="Email"
          onChange={handleInputChange}
          name="email"
          onFocus={()=>{setStep("3")}}
        />
         <input
          type="twitter"
          className={styles.simpleInput}
          placeholder="Usuario Twitter"
          onChange={handleInputChange}
          name="twitter"
          onFocus={()=>{setStep("4")}}
        />
        <input
          list="cities"
          type="city"
          className={styles.simpleInput}
          placeholder="Localidad"
          onChange={handleInputChange}
          name="city"
          onFocus={()=>{setStep("5")}}
        />
        <datalist id="cities">
          {cities?.map(city => {
            return <option key={city} value={city} />
          })}
        </datalist>
        {/* A COMPONENTE */}
        {/* <select
          className={styles.simpleInput}
          onChange={handleInputChange}
          value={data.city}
          // value={value ? value : ""}
          name="city"
        >
          <option selected hidden>
            Localidad
          </option>
          <option className={styles.option} value="EXT">
            No soy residente argentino
          </option>
          <option className={styles.option} value="BA">
            Buenos Aires
          </option>
          <option value="CABA">Capital Federal</option>
          <option value="CA">Catamarca</option>
          <option value="CH">Chaco</option>
          <option value="CB">Córdoba</option>
          <option value="CR">Corrientes</option>
          <option value="ER">Entre Ríos</option>
          <option value="FO">Formosa</option>
          <option value="JY">Jujuy</option>
          <option value="LP">La Pampa</option>
          <option value="LR">La Rioja</option>
          <option value="MZ">Mendoza</option>
          <option value="MI">Misiones</option>
          <option value="NQN">Neuquén</option>
          <option value="RN">Río Negro</option>
          <option value="SA">Salta</option>
          <option value="SJ">San Juan</option>
          <option value="SL">San Luis</option>
          <option value="SC">Santa Cruz</option>
          <option value="SF">Santa Fe</option>
          <option value="SE">Santiago del Estero</option>
          <option value="TF">Tierra del Fuego</option>
          <option value="TU">Tucumán</option>
        </select> */}
        {/* FIN DE A COMPONENTE */}
        <br />
        {/* CHECKBOX SECTION START */}
        <CustomCheckBox
          isFirstQ
          selectedInput={selectedInput}
          setSelectedInput={selectedInput}
          handleChange={handleChange}
        />
        <div className={styles.divider} />
        <CustomCheckBox
          selectedInput2={selectedInput2}
          setSelectedInput2={selectedInput2}
          handleChange2={handleChange2}
        />
        <div className={styles.divider} />
        {/* CHECKBOX SECTION END */}
        {/* TERMS START */}
        <div className={styles.termsContainer}>
          <CheckboxInput
            name="remember-me"
            label="Remember Me"
            isChecked={isChecked}
            setIsChecked={setIsChecked}
            setStep={setStep}
          />
          <h4 className={styles.termsText}>
            He leído y acepto&nbsp;
            <a className={styles.link} target="_blank" href="/SorteoBudGamingFinalLLA.pdf">
              Bases y Condiciones
            </a>
            ,&nbsp;
            <a
              className={styles.link}
              target="_blank"
              href="/Terminos-Condiciones.pdf"
            >
              Términos y Condiciones
            </a>
            ,&nbsp;
            <a
              className={styles.link}
              target="_blank"
              href="/PoliticadePrivacidad.pdf"
            >
              Políticas de Privacidad
            </a>
            &nbsp;y activaciones de marketing.
          </h4>
        </div>
        {/* TERMS END */}
        {/* SUBMIT START */}
        <div style={{ paddingBottom: "20px", alignSelf: "center" }}>
          <input type="submit" value="Enviar" className={styles.btnSubmit} />
        </div>
        <MessageValidate />
      </form>
    </div>
  )
}
