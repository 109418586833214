import React from "react"
import SEO from "./../seo"
import Footer from "./../Footer"
import styles from "./../../scss/pages/_esport.module.scss"

import logo from "./../../images/Esports/logo-bud.svg"
import { Container } from "react-bootstrap"

export default function Success() {
  return (
    <div styles={{ overflow: "hidden" }}>
      <SEO title="Esport" />
      <Container fluid className="m-0 p-0">
        <section>
          <div className={styles.wrap}>
            <div className={styles.container}>
              <div>
                <img src={logo} className={styles.logo} />
              </div>
              <div className={styles.wrapText}>
                <div className={styles.titleContainer}>
                  <h1 className={styles.title}>MISSION PASSED!</h1>
                </div>
                <h1 className={styles.infoTextOne}>
                Antes del 13/4 anunciaremos a los ganadores <br />a través de Email y Twitter, <br />con el QR para ingresar a GAMERGY Argentina.
                </h1>
              </div>
              <div>
                <h1 className={styles.infoTextTwo}>
                  Seguinos en Twitter y entérate de <br />
                  todas las novedades
                </h1>
                <a
                  className={styles.btnContainer}
                  target="_blank"
                  href="https://twitter.com/budargentina"
                >
                  <div className={styles.followBtn}>Seguirnos</div>
                </a>
              </div>
            </div>
          </div>
        </section>
        <div className={styles.footer}>
          <Footer />
        </div>
      </Container>
    </div>
  )
}
