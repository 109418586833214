import React, { useState, useEffect } from "react"
import { Row, Col, Container } from "react-bootstrap"
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom"
import SEO from "../components/seo"
import arrowDown from "./../images/icons/arrowDown.png"
import Footer from "../components/Footer"
import Main from "../components/Esport/Main"
import styles from "./../scss/pages/_esport.module.scss"
import Form from "../components/Esport/Form"
import Success from "../components/Esport/Success"
import AgeGate from "../components/AgeGate"
import Cookies from "js-cookie"

export default function Esports() {
  const [age, setAge] = useState(true)
  const [step,setStep]= useState("")

  useEffect(() => {
    let td = Cookies.get("_td")
    window.dataLayer.push({
      brand: "Budweiser",
      Zone: "SAZ",
      country: "ARG",
      city: "CABA",
      campaign: `${
        window.location.search.includes("?cmp=")
          ? window.location.search.split("?cmp=")[1].split("&")[0]
          : ""
      }`,
      cms: "0",
      event: "pageView",
      language: "es",
      login: false,
      pageName: "Esports",
      pageType: "Basic page",
      SiteType: "Brand",
      product: "NA",
      sku: "NA",
      userUid: "",
      url: "https://www.budweiser.com.ar/esports",
      url_campaign: `${
        window.location.search.includes("utm_campaign=")
          ? window.location.search.split("utm_campaign=")[1]
          : ""
      }`,
      Step_number: step ? `step_${step}` : "",
    })
  }, [step])

  useEffect(() => {
    // console.log("ok!")
    let cookie = Cookies.get("age")
    let local = sessionStorage.getItem("age")
    // console.log("Cookie",cookie)
    if (cookie || local) setAge(false)
  }, [])
  return !age ? (
    <Router>
      <Route exact path="/esports">
        <div styles={{ overflow: "hidden" }}>
          <SEO title="Esports" />
          <Container fluid className="m-0 p-0">
            <section>
              <Main />
            </section>
            <Row className="d-flex justify-content-center m-0">
              <Col xs={"auto"}>
                <a
                  onClick={e =>
                    document
                      .querySelector("#FormSection")
                      .scrollIntoView({ behavior: "smooth" })
                  }
                >
                  <img src={arrowDown} className={styles.ArrowDownSection} />
                </a>
              </Col>
            </Row>
            <section id="FormSection">
              <Form setStep={setStep} />
            </section>
            <Footer />
          </Container>
        </div>
      </Route>
      <Route path="/esports/success">
        <Success />
      </Route>
    </Router>
  ) : (
    <AgeGate path="/esports" />
  )
}
