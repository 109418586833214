import React from "react"
import styles from "../../scss/components/Esports/_CustomCheckBox.module.scss"

export default function CustomCheckBox({
  isFirstQ,
  selectedInput,
  setSelectedInput,
  selectedInput2,
  setSelectedInput2,
  handleChange,
  handleChange2,
}) {
  const RadioInput = ({
    name,
    name2,
    label,
    value,
    isChecked,
    handleChange,
    handleChange2,
  }) => {
    const handleRadioChange = e => {
      const { id } = e.currentTarget
      handleChange(id)
    }
    const handleRadioChange2 = e => {
      const { id } = e.currentTarget
      handleChange2(id)
    }

    return (
      <div>
        <div style={{ display: "flex", height: "100%" }}>
          <input
            type="radio"
            className={styles.customRadio}
            name={name}
            name2={name2}
            id={value}
            checked={isChecked}
            onChange={isFirstQ ? handleRadioChange : handleRadioChange2}
          />
          <label htmlFor={value}>
            <h3 className={styles.labelText}>{label}</h3>
          </label>
        </div>
      </div>
    )
  }

  const RadioGropupInput = () => {
    return (
      <div>
        {isFirstQ ? (
          <>
            <RadioInput
              name="option"
              value="firstq-no"
              label="No lo asocio para nada"
              isChecked={selectedInput === "firstq-no"}
              handleChange={handleChange}
            />
            <RadioInput
              name="option"
              value="firstq-poco"
              label="Lo asocio un poco"
              isChecked={selectedInput === "firstq-poco"}
              handleChange={handleChange}
            />
            <RadioInput
              name="option"
              value="firstq-maso"
              label="Más o menos"
              isChecked={selectedInput === "firstq-maso"}
              handleChange={handleChange}
            />
            <RadioInput
              name="option"
              value="firstq-bastante"
              label="Lo asocio bastante"
              isChecked={selectedInput === "firstq-bastante"}
              handleChange={handleChange}
            />
            <RadioInput
              name="option"
              value="firstq-completamente"
              label="Lo asocio completamente"
              isChecked={selectedInput === "firstq-completamente"}
              handleChange={handleChange}
            />
          </>
        ) : (
          <>
            <RadioInput
              name2="option"
              value="secondq-si"
              label="Sí"
              isChecked={selectedInput2 === "secondq-si"}
              handleChange2={handleChange2}
            />
            <RadioInput
              name2="option"
              value="secondq-no"
              label="No"
              isChecked={selectedInput2 === "secondq-no"}
              handleChange2={handleChange2}
            />
          </>
        )}
      </div>
    )
  }
  return (
    <div className={styles.container}>
      <h1 className={styles.title}>
        {isFirstQ
          ? "¿Cuánto asocias a Budweiser con el Gaming?"
          : "¿Tomás cerveza cuando estás jugando o viendo eSports?"}
      </h1>
      <RadioGropupInput
        selectedInput={selectedInput}
        setSelectedInput={setSelectedInput}
        handleChange={handleChange}
        selectedInput2={selectedInput2}
        selectedInput2={setSelectedInput2}
        handleChange2={handleChange2}
      />
    </div>
  )
}
